import { useCallback, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useAppDispatch, useTypedSelector } from './reduxHooks/redux';

import {
  addNewNotification,
  setUrgentNotification
} from 'reduxStore/slices/notificationSlice';
import { ServerString } from '../config';
import { INotification } from 'reduxStore/types/notificationTypes';
import { setApplicationListUpdated } from 'reduxStore/slices/applicationSlice';

const notificationSound = new Audio(require('../sounds/notify.mp3'));
const notificationEvents = [
  'OutgoingCorrespondence.ResponsibleSent',
  'OutgoingCorrespondence.ResponsibleUpdated',
  'IncomingCorrespondence.New',
  'IncomingCorrespondence.Forward',
  'OutgoingCorrespondence.Forward',
  'Task.StatusChanged',
  'Task.Assigned',
  'Application.AssignedResponsible',
  'Application.Created',
  'Application.AssignedDispatcher',
  'Application.StatusCompleted',
  'Application.StatusChanged'
];

const useSocket = (sound: Boolean = true) => {
  const isAuth = useTypedSelector(state => state.auth.isLoggedIn);
  const token = localStorage.getItem('tokenAccess');
  const dispatch = useAppDispatch();
  const wsString = `${ServerString}/notifications`;
  const socket = io(wsString, {
    auth: {
      token: `Bearer ${token}`
    },
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 3000
  });

  const handleNotification = useCallback(
    async (e: any) => {
      dispatch(addNewNotification(e));
      if (sound) {
        notificationSound
          .play()
          .catch(error =>
            console.warn(`Could not play notification sound: ${error}`)
          );
      }
    },
    [dispatch, sound]
  );

  useEffect(() => {
    if (isAuth) {
      socket.connect();

      socket.on('connect', () => {
        console.log('socket connect success');
      });
      socket.on('connect_error', err => {
        console.log(`connect_error due to ${err.message}`);
        socket.io.opts.transports = ['polling', 'websocket'];
        socket.auth = { token: `Bearer ${token}` };
        socket.connect();
      });

      for (const notificationEvent of notificationEvents) {
        socket.on(notificationEvent, handleNotification);
      }

      socket.onAny(async (eventName: string) => {
        if (eventName.startsWith('Application')) {
          dispatch(setApplicationListUpdated(Date.now()));
        }
      });

      socket.on('urgent_service', (e: INotification[]) => {
        dispatch(setUrgentNotification(e?.length !== 0 ? e[0] : null));
      });
      socket.on('disconnect', reason => {
        console.log('socket disconnect');
        if (reason === 'io server disconnect') {
          socket.connect();
        }
      });
    } else {
      socket.close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);
};
export default useSocket;
