import React from 'react';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import './assets/scss/user.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { setupStore } from './reduxStore';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('main');
const root = createRoot(container!);

export const store = setupStore();

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Main>
        <App />
      </Main>
    </Provider>
  </BrowserRouter>
);
